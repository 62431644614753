import actions from '../redux/game/actions';
import ControllerMock from './controllerMock';
import { store } from '../index';
import selectors from '../redux/game/selectors';
import { eGameState } from '../redux/game/reducer';

const enableMock = false;

const initManagerParams = {
  developmentGetSessionPath: 'https://int.dev.onlyplay.net/integration/request_frame/?game=mythsofbastet&no_redirect&no_op_launcher',
  cheatsParams:  ['force_reels', 'force_freespins', 'force_jackpot'],
  baseData: {
    ver: '2.0'
  }
};

export const requestConfig = async () => {
  window.OPWrapperService.serverManager.initManagerGameRequests(initManagerParams);
  await window.OPWrapperService.serverManager.sendGameInitRequest();

  const response = enableMock ?
    await ControllerMock.requestConfig() :
    await sendRequest({ }, 'config');

  response.available_icons = ControllerMock.availableSymbols;
  response.available_jugs_icons = ControllerMock.jugSymbols;

  window.OPWrapperService.freeBetsController.setData(response.freebets);
  store.dispatch(actions.setData(response));

  const state = store.getState().game;
  const freespins = response.bonus?.condition;
  if (state.balance < state.bets[state.currentBetIndex] && !freespins) {
    store.dispatch(actions.setMaxBet());
  }
};

export const requestNewGame = async () => {
  const state = store.getState();
  const amount = selectors.getBetAmount(state);
  const betIndex = selectors.getCurrentBetIndex(state);
  const balance = selectors.getBalance(state);

  if (!state.game.freespinsEnabled && !window.OPWrapperService.freeBetsController.isActive) {
    store.dispatch(actions.setBalance(balance - amount));
  }
  const data = {
    betIndex,
  };

  let response = enableMock ?
    await ControllerMock.requestNewGame(amount) :
    await sendRequest(data, 'spin');

  if (response.error) {
    store.dispatch(actions.setGameState(eGameState.EGS_CAN_STOP));
  } else {
    response.available_icons = ControllerMock.availableSymbols;
    response.available_jugs_icons = ControllerMock.jugSymbols;
    window.OPWrapperService.freeBetsController.setData(response.freebets);
    store.dispatch(actions.setNewGame(response));
  }
};

export const requestBuyBonusGame = async (type) => {
  const state = store.getState();
  const amount = selectors.getBetAmount(state);
  const balance = selectors.getBalance(state);
  const betIndex = selectors.getCurrentBetIndex(state);

  if (!state.game.freespinsEnabled) {
    store.dispatch(actions.setBalance(balance - amount));
  }

  const data = {
    betIndex,
    type: type,
  };

  const response = await sendRequest(data, 'buy');

  if (response.error) {
  } else {
    response.available_icons = ControllerMock.availableSymbols;
    response.available_jugs_icons = ControllerMock.jugSymbols;
    store.dispatch(actions.setBonusGame(response));
  }
};

const sendRequest = async (data, id, urlPath = 'game_request') => {
  data.id = id;
  return await window.OPWrapperService.serverManager.sendGameRequest(data, urlPath);
};
